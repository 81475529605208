<template>
    <div id="pdf-print-ready">
        <v-toolbar color="primary" dark>
            <v-toolbar-title>
                <v-icon large left>mdi-home</v-icon>
                {{ $t("employment-reference-form.title") }}
            </v-toolbar-title>
        </v-toolbar>
        
        <v-card-text>
            <v-row no-gutters class="my-0">
                <p class="subtitle-1">
                    {{$t("common.to")}}: {{contact_name}}
                </p>
            </v-row>
            <v-row no-gutters class="my-0">
                <p class="subtitle-1">
                    {{$t("employment-reference-form.contact_msg")}}
                </p>
            </v-row>
            <v-row no-gutters class="my-0">
                <p class="subtitle-1 font-weight-bold">
                    {{applicant_name}}
                </p>
            </v-row>
            <v-row no-gutters class="my-0">
                <v-col cols="3">
                    <p class="subtitle-1 font-weight-bold">
                        {{$t("employment-reference-form.dob")}}
                        <br/>
                        {{$t("employment-reference-form.start_date")}}
                        <br/>
                        {{$t("employment-reference-form.end_date")}}
                        <br/>
                        {{$t("employment-reference-form.monthly_income")}}
                        <br/>
                        {{$t("employment-reference-form.position")}}
                    </p>
                </v-col>
                <v-col cols="9">
                    <p class="subtitle-1">
                        {{applicant_birthdate}}
                        <br/>
                        {{start_date}}
                        <br/>
                        {{end_date}}
                        <br/>
                        {{"$"+ monthly_income}}
                        <br/>
                        {{applicant_position}}
                    </p>
                </v-col>
            </v-row>
        </v-card-text>
        
        <hr/>

        <v-card-text>

            <v-row no-gutters class="my-0">
                <p class="subtitle-1 font-weight-bold mb-1">
                    {{$t("employment-reference-form.answer_all_questions")}}
                </p>
            </v-row>
        
            <v-form ref="form">
                <v-row no-gutters class="my-0">
                    <v-col cols="12">
                        <v-radio-group dense row>
                            <v-radio
                                value="fullTime"
                                :label="$t('employment-reference-form.employment_type_values.fullTime')"
                            ></v-radio>
                            <v-radio
                                value="partTime"
                                :label="$t('employment-reference-form.employment_type_values.partTime')"
                            ></v-radio>
                            <v-radio
                                value="seePayStub"
                                :label="$t('employment-reference-form.employment_type_values.seePayStub')"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-menu
                                ref="dateMenu1"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        outlined
                                        :label="$t('employment-reference-form.start_date') + ' (mm/dd/yyy)'"
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        v-model="dummyFiller"
                                    ></v-text-field>
                                </template>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                ref="dateMenu2"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        class="ml-2"
                                        dense
                                        outlined
                                        :label="$t('employment-reference-form.end_date')"
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        placeholder="Placeholder"
                                        v-model="dummyFiller"
                                    ></v-text-field>
                                </template>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-row>
                <hr/>
                <br/>
                <v-row no-gutters class="my-0">
                    <v-col cols="3">
                        <v-subheader class="subtitle-1">{{
                            $t('employment-reference-form.position')
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            dense
                            outlined
                            v-model="title"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="3">
                        <v-subheader class="subtitle-1">{{
                            $t('employment-reference-form.employment_type')
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="4">
                        <v-radio-group dense row class="mt-1">
                            <v-radio
                                value="salary"
                                :label="$t('employment-reference-form.compensation_type.salary')"
                            ></v-radio>
                            <v-radio
                                value="hourly"
                                :label="$t('employment-reference-form.compensation_type.hourly')"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="5">
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="3">
                        <v-subheader class="subtitle-1">{{
                            $t('employment-reference-form.monthly_salary')
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            dense outlined>
                        </v-text-field>
                    </v-col>
                    <v-col cols="5">
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="3">
                        <v-subheader class="subtitle-1">{{
                            $t('employment-reference-form.hourly_wage')
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            dense outlined>
                        </v-text-field>
                    </v-col>
                    <v-col cols="5"></v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="3">
                        <v-subheader class="subtitle-1">{{
                            $t('employment-reference-form.hours_worked_per_week')
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            dense outlined>
                        </v-text-field>
                    </v-col>
                    <v-col cols="4">
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="12">
                        <v-text-field
                            outlined
                            class="ml-4"
                            :label="$t('common.additional_comments')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <hr/>
                <br/>
                <v-row no-gutters class="my-0">
                    <v-col cols="2">
                        <v-subheader>{{
                            $t('employment-reference-form.your_name')
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-subheader class="ml-5">{{
                            $t('employment-reference-form.position')
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field 
                        dense outlined
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            
        </v-card-text>
    </div>
</template>

<script>
export default {
    name: "employment-reference-pdf",
    props: {
        reference: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            is_complete: this.reference.is_complete,
            applicant_name: this.reference.applicant.name,
            applicant_position: this.reference.reference.address.position,
            start_date: this.reference.reference.address.start_date,
            end_date: this.reference.reference.address.end_date,
            monthly_income: this.reference.reference.address.monthly_income,
            contact_name: this.reference.reference.contact.name,
            applicant_birthdate: this.reference.applicant.birthdate,
            title: ' ',
        };
    }
};
</script>