var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "pdf-print-ready" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "primary", dark: "" } },
        [
          _c(
            "v-toolbar-title",
            [
              _c("v-icon", { attrs: { large: "", left: "" } }, [
                _vm._v("mdi-home")
              ]),
              _vm._v(
                " " + _vm._s(_vm.$t("employment-reference-form.title")) + " "
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c("v-row", { staticClass: "my-0", attrs: { "no-gutters": "" } }, [
            _c("p", { staticClass: "subtitle-1" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("common.to")) +
                  ": " +
                  _vm._s(_vm.contact_name) +
                  " "
              )
            ])
          ]),
          _c("v-row", { staticClass: "my-0", attrs: { "no-gutters": "" } }, [
            _c("p", { staticClass: "subtitle-1" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("employment-reference-form.contact_msg")) +
                  " "
              )
            ])
          ]),
          _c("v-row", { staticClass: "my-0", attrs: { "no-gutters": "" } }, [
            _c("p", { staticClass: "subtitle-1 font-weight-bold" }, [
              _vm._v(" " + _vm._s(_vm.applicant_name) + " ")
            ])
          ]),
          _c(
            "v-row",
            { staticClass: "my-0", attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "3" } }, [
                _c("p", { staticClass: "subtitle-1 font-weight-bold" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("employment-reference-form.dob")) + " "
                  ),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("employment-reference-form.start_date")) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("employment-reference-form.end_date")) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("employment-reference-form.monthly_income")
                      ) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("employment-reference-form.position")) +
                      " "
                  )
                ])
              ]),
              _c("v-col", { attrs: { cols: "9" } }, [
                _c("p", { staticClass: "subtitle-1" }, [
                  _vm._v(" " + _vm._s(_vm.applicant_birthdate) + " "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.start_date) + " "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.end_date) + " "),
                  _c("br"),
                  _vm._v(" " + _vm._s("$" + _vm.monthly_income) + " "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.applicant_position) + " ")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("hr"),
      _c(
        "v-card-text",
        [
          _c("v-row", { staticClass: "my-0", attrs: { "no-gutters": "" } }, [
            _c("p", { staticClass: "subtitle-1 font-weight-bold mb-1" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("employment-reference-form.answer_all_questions")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-radio-group",
                        { attrs: { dense: "", row: "" } },
                        [
                          _c("v-radio", {
                            attrs: {
                              value: "fullTime",
                              label: _vm.$t(
                                "employment-reference-form.employment_type_values.fullTime"
                              )
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              value: "partTime",
                              label: _vm.$t(
                                "employment-reference-form.employment_type_values.partTime"
                              )
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              value: "seePayStub",
                              label: _vm.$t(
                                "employment-reference-form.employment_type_values.seePayStub"
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-menu", {
                            ref: "dateMenu1",
                            attrs: {
                              transition: "scale-transition",
                              "offset-y": "",
                              "max-width": "290px",
                              "min-width": "290px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              label:
                                                _vm.$t(
                                                  "employment-reference-form.start_date"
                                                ) + " (mm/dd/yyy)",
                                              "prepend-icon": "mdi-calendar"
                                            },
                                            model: {
                                              value: _vm.dummyFiller,
                                              callback: function($$v) {
                                                _vm.dummyFiller = $$v
                                              },
                                              expression: "dummyFiller"
                                            }
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-menu", {
                            ref: "dateMenu2",
                            attrs: {
                              "close-on-content-click": false,
                              transition: "scale-transition",
                              "offset-y": "",
                              "max-width": "290px",
                              "min-width": "290px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              label: _vm.$t(
                                                "employment-reference-form.end_date"
                                              ),
                                              "prepend-icon": "mdi-calendar",
                                              placeholder: "Placeholder"
                                            },
                                            model: {
                                              value: _vm.dummyFiller,
                                              callback: function($$v) {
                                                _vm.dummyFiller = $$v
                                              },
                                              expression: "dummyFiller"
                                            }
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("hr"),
              _c("br"),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(_vm.$t("employment-reference-form.position"))
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: { dense: "", outlined: "" },
                        model: {
                          value: _vm.title,
                          callback: function($$v) {
                            _vm.title = $$v
                          },
                          expression: "title"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "5" } })
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("employment-reference-form.employment_type")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-radio-group",
                        { staticClass: "mt-1", attrs: { dense: "", row: "" } },
                        [
                          _c("v-radio", {
                            attrs: {
                              value: "salary",
                              label: _vm.$t(
                                "employment-reference-form.compensation_type.salary"
                              )
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              value: "hourly",
                              label: _vm.$t(
                                "employment-reference-form.compensation_type.hourly"
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "5" } })
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("employment-reference-form.monthly_salary")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", { attrs: { dense: "", outlined: "" } })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "5" } })
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("employment-reference-form.hourly_wage")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", { attrs: { dense: "", outlined: "" } })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "5" } })
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "employment-reference-form.hours_worked_per_week"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", { attrs: { dense: "", outlined: "" } })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "4" } })
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        staticClass: "ml-4",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("common.additional_comments")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("hr"),
              _c("br"),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-subheader", [
                        _vm._v(
                          _vm._s(_vm.$t("employment-reference-form.your_name"))
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", { attrs: { dense: "", outlined: "" } })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-subheader", { staticClass: "ml-5" }, [
                        _vm._v(
                          _vm._s(_vm.$t("employment-reference-form.position"))
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", { attrs: { dense: "", outlined: "" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }